/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-07 15:48:21 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-02-23 15:32:47
 */
<template>
  <div>
    <el-row type="flex" align="middle">
      <el-col :span="24">
        <el-tabs :tab-position="tabPosition" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="ta.label" :name="ta.value" v-for="(ta,j) in tabs" :key="j">
            <el-row :gutter="20">
              <el-col :span="12" v-for="(li,i) in list" :key="i">
                <div class="cont">
                  <div class="title">
                    <span @click="goTo(li)">{{li.Name}}</span>
                    <i
                      @click="play(li)"
                      class="play"
                      v-if="li.Category == 0"
                    ></i>
                  </div>
                  <div v-html="li.Explain" class="explain"></div>
                </div>
              </el-col>
            </el-row>
            <div v-if="list.length<=0">
              <no-data></no-data>
            </div>
            <el-pagination
              v-if="list.length>0"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page"
              :page-size="pageSize"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postStudentCompareFace"
    ></camera>
    <audio ref="audio"></audio>
  </div>
</template>

<script>
import { questionBankQueryUserWordsByAnswers } from "@/api/personal";
import { studentCompareFace } from "@/api/api";
import camera from "@/components/canvasCamera.vue";
import noData from "@/components/noData";
export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      parsingShow:true,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      answer:0,//熟悉不熟悉陌生对应的0,1，2
      tabPosition:'left',
      activeName: '0',
      list:[],
      tabs:[
        {label:"熟悉",value:"0"},
        {label:"不熟悉",value:"1"},
        {label:"陌生",value:"2"}
        ],
      dialogVisible:false,
      jump: {},
      user: {},
    };
  },
  components: {
    camera,noData
  },
  created() {
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.init();
  },
  watch: {
    $route: "init",
  },
  methods: {
    getClose() {
      this.dialogVisible = false;
    },
    // 人脸识别
    async postStudentCompareFace(flag) {
      // const formData = new FormData();
      // formData.append("file", file);
      // const res = await studentCompareFace(formData);
      if (flag == true) {
        // this.$message.success(res.msg);
        this.$router.push({ path: this.jump.url, query: this.jump.ob });
      } else {
        this.$message.error("识别失败");
        this.dialogVisible = false;
      }
    },
    goTo(obj){
      if(this.$route.query.CourseActive == false)
      {
        this.$message.error("对不起，当前课程不在有效期内，不能练习");
        return false;
      }
      let ob = {
        CourseId: obj.CourseId,
        ChapterId: 0,//obj.ChapterId,
        Name: obj.text,
        UserID:this.user.Id,
        Answer:this.activeName,
        WordId:obj.Id,
        questionCategory:-1
      };
      this.jump.url = "/onlineQuestionBank/recitationMode";
      this.jump.ob = ob;
      this.$router.push({ path: this.jump.url, query: this.jump.ob });
      // this.dialogVisible = true;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
    handleClick(tab, event){
      this.page = 1;
      this.answer = this.activeName;
      this.init();
    },
    //播放词语
    play(obj) {
      this.$refs.audio.src =
        "https://dict.youdao.com/speech?audio=" + obj.Name;
      this.$refs.audio.play();
    },
    async init() {
      let studentId = this.userInfo.Id
      this.route = this.$route.query;
      let parm = {
        "courseId":this.route.CourseId,
        "studentId":studentId,
        "pageIndex":this.page,
        "pageSize":this.pageSize,
        "answer":this.answer
      }
      const res = await questionBankQueryUserWordsByAnswers(parm);
      if (res.success === true) {
        this.list = res.response.data;
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-row {
  .el-col {
    .cont{
      border-bottom: 1px solid #EEE;
      padding: 20px 0;
    }
    .el-tabs{
      .el-tabs__content{
      }
    }
    .title {
      flex: 1;
      margin-bottom: 10px;
      font-weight:bold;
      display:flex;
      align-items: center;
      span{
        flex: 1;
        width: 80%;
        word-wrap:break-word;
        cursor: pointer;
      }
      .play {
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: middle;
        margin-left: 15px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../../../assets/images/audio.png");
      }
      p {
        line-height: 22px;
        height: 22px;
      }
    }
  }
}
/deep/.explain{color:#a1a1a1;white-space: nowrap;overflow: hidden;text-overflow:ellipsis;}
/deep/.explain > * {display:none;}
/deep/.explain p:nth-child(1){display:block;width:100%;white-space: nowrap;overflow: hidden;text-overflow:ellipsis;}
.el-tab-pane .el-row .el-col:nth-child(1) .cont{padding-top:10px;}
.el-tab-pane .el-row .el-col:nth-child(2) .cont{padding-top:10px;}
</style>